/**
 * Direttiva che espande un elemento fino al fondo della finestra
 */

export interface IViewportOffset {
    top: number,
    bottom: number
};
export interface IExpandToBottomDirectiveScope extends ng.IScope {
    viewportOffset: IViewportOffset;
    expand: boolean;
}

angular.module('app').directive('expandToBottom', ($window, $timeout, $animateCss) => {
    return {
        restrict: 'AEC',
        scope: {
            expand: "="
        },
        link: ($scope: IExpandToBottomDirectiveScope, element: JQuery, attr: ng.IAttributes) => {

            // La direttiva utilizza un timeout affinchè si avvii una volta caricata la pagina, dato che lavoro con le misure di elementi rispetto alla finestra
            $timeout(() => {
                let myEl = element, // elemento su cui sta lavorando la direttiva
                    scrollValue = 0; // pixel scrollati
                let scrollTimeout: any = null // timeout relativo allo scroll
                let resizeTimeout: any = null // timeout relativo allo scroll
                let isExpanded = false; // Controllo se la barra è già aperta

                // Funzione principale che calcola la distanza dell'elemento selezionato dal bordo della finestra, tenendo conto anche
                // dell'eventuale spazio tolto o aggiunto dallo scroll o dal resize
                function getViewportOffset(myElement: any, extraSpace: any) {
                    let $window = $(window),
                        scrollTop = $window.scrollTop(),
                        scrollBottom = $(document).height() - $window.scrollTop() - $window.height(),
                        offset = myElement.offset();
                    let ret: IViewportOffset = null;
                    ret = {
                        top: offset.top - scrollTop,
                        bottom: $(window).height() - myElement.offset().top  + extraSpace - 32
                    }
                    return ret;
                }

                // Una volta aperta la pagina, il DOM potrebbe non essere caricato completamente quindi aggiungo un altro timeout e procedo con il calcolo della distanza 
                // dell'elemento dal fondo della finestra
                $timeout(() => {
                    element[0].style.overflowX = "auto";
                    document.body.style.overflow = "auto";
                    $scope.viewportOffset = getViewportOffset(myEl, 0);
                }, 200);

                // Allo scroll della pagina non solo calcolo la posizione dell'elemento, ma ci aggiungo anche la quantità di pixel scrollati
                $(window).scroll((event) => {
                    clearTimeout(scrollTimeout);
                    // Dato che alcuni browser non fermano subito lo scroll (soprattutot nel mobile), inseriesco
                    // un timeout che in ogni caso viene pulito se un nuovo scroll viene fatto
                    scrollTimeout = setTimeout(() => {
                        let scrolled = $(document).scrollTop() - scrollValue;
                        scrollValue = $(document).scrollTop();

                        $scope.viewportOffset = getViewportOffset(myEl, scrollValue);
                        if ($scope.expand === true) {
                            resize();
                        }
                    }, 100);
                });

                // Calcolo la distanza dell'elemento dal fondo anche quando applico un resize alla finestra e, se il contenitore è già espanso, cambio la sua altezza
                angular.element($window).bind('resize', () => {
                    clearTimeout(resizeTimeout);
                    resizeTimeout = setTimeout( () => {
                        // nel calcolo è necessario passare non solo l'elemento, ma anche la quantità di pixel che ho tolto o aggiunto con il resize (.scrollTop())
                        $scope.viewportOffset = getViewportOffset(myEl, $(document).scrollTop());
                        if ($scope.expand === true) {
                            resize();
                        }
                    }, 100);
                });

                /// Espande la barra selezionata
                let resize = () => {
                    if(isExpanded == false){
                        // Per prima cosa faccio vedere il contenuto
                        element[0].children[0].classList.remove('display-none');
                        element[0].children[0].classList.add('display-block');
                        // Evito lo scroll orizzontale della contenuto della barra espansa
                        element[0].style.overflowX = "hidden";
                        // Devo scrollare (verticalmente) solo il contenuto della barra, quindi disattivo lo scroll dell'intera pagina
                        document.body.style.overflowY = "hidden";
                        // Modifico l'altezza minima con i pixel calcolati (distanza dell'elemento dal fondo finestra)
                        // è necessario utilizzare il componente nativo di angular animateCss in quanto le transition 
                        // dei css non funzionano per problemi di DOM, essendo in una direttiva
                        $animateCss(myEl, {
                            easing: 'ease',
                            from: { height:'0px' },
                            to: { height: $scope.viewportOffset.bottom + 'px' },
                            duration: 0.7
                        }).start().then(() => {
                            isExpanded = true;
                        })
                    } else {
                        element[0].style.height = $scope.viewportOffset.bottom + "px";
                    }
                };

                // Chiude la barra selezionata riportando tutti i valori a quelli di default
                let unResize = () => {
                    // Poichè di default $scope.expand === false, quando accedo alla pagina per la prima volta entrerò qui. Non avendo calcolato
                    // quindi la distanza dell'elemento dalla fine della window (sarebbe stata una operazione in più e non necessaria) prevengo
                    // l'errore 'Cannot read property 'bottom' of undefined'
                    if($scope.viewportOffset && $scope.viewportOffset.bottom){
                        // Definisco l'animazione
                        var animation = $animateCss(myEl, {
                            easing: 'ease',
                            from: { height: $scope.viewportOffset.bottom + 'px' },
                            to: { height: '0px' },
                            duration: 0.7
                        });
                        // e che cosa fare al suo termine, cioè togliere il display-block. Questo affinchè il contenuto dell'elemento espanso non scompaia subito al click
                        // che avvia l'unResize, invero risulterebbe assai brutto
                        animation.start().done(() => {
                            element[0].children[0].classList.remove('display-block');
                            element[0].children[0].classList.add('display-none');
                            element[0].style.overflowX = "auto";
                            document.body.style.overflowY = "auto";
                            isExpanded = false;
                        });
                    }
                }

                function expand() {
                    if ($scope.expand === true) {
                        resize();
                    } else if ($scope.expand === false) {
                        unResize();
                    }
                }
                
                // Espando l'elemento solo se me lo impone chi utilizza la direttiva
                if (attr.hasOwnProperty("expand")) {
                    $scope.$watch("expand", expand);
                    expand();
                }
            });
        }
    };
});